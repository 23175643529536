import React from 'react'
import tw, { styled } from 'twin.macro'


const Hamburger = styled('div')`
  ${tw`flex items-center justify-center cursor-pointer hover:opacity-70 sm:invisible visible`}
  transition-timing-function: ease-in-out;
  transition-duration: .15s;
  transition-property: opacity;
  width: 48px;
  height: 31px;
`;

const Box = styled('div')`
  ${tw`relative inline-block w-full h-full`}
`;

const BoxInner = styled('div')`
  ${tw`bg-gray-600 absolute`}
  border-radius: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  margin-top: -3px;
  width: 48px;
  height: 5px;
  top: 3px;
  &:before {
    ${tw`bg-gray-600 absolute`}
    top: 12px;
    transition-property: transform,opacity;
    transition-timing-function: ease;
    transition-duration: .15s;
    width: 48px;
    height: 5px;
    content: "";
    display: block;
    border-radius: 4px;
  }
  &:after {
    ${tw`bg-gray-600 absolute`}
    top: 24px;
    width: 48px;
    height: 5px;
    bottom: -12px;
    content: "";
    display: block;
    border-radius: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;

  }
`;

export const  HamburgerMenu = () => {

  return (
     <Hamburger>
       <Box>
        <BoxInner>
        </BoxInner>
       </Box>
     </Hamburger>
  )
}

