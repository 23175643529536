/**
 * SEO / Global Helmet component
 */

import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

export interface IHeaderData {
  title?: string;
  description?: string;
}

export const Header = ({prop}) => {
  // const [loaded, setLoaded] = useState(false);

//   componentDidMount(() => {
//     setLoaded(true)
//   })
  const description = prop?.description || 'Mediroot to największa i najnowoczśniejsza klinika stomatologiczna w Wągrowcu. Zajmujemy się ortodoncją, protetyką, stomatologią zachowawczą, periodontologią, endodoncją i stomatologią estetyczną. ';

  return (
    <Helmet
    htmlAttributes={{ lang:'pl' }}
    title={ prop?.title || "Mediroot" }
    meta={[
        { property:"og:image", content: "" },
        { property:"twitter:image", content: "" },
      ]}
    >
      <link rel="canonical" href={ prop?.canonicalUrl || 'https://www.mediroot.pl/' } />
      <meta name="google-site-verification" content="AN_B4xPaNar0NZEZoOQYOKZn878BZdwT9H1mXURdV-M" />
      <meta http-equiv="x-dns-prefetch-control" content="on"/>
      <meta http-equiv="x-rim-auto-match" content="none"/>
      <meta charSet="utf-8" />
      <meta name="description" content={description}/>
      <meta property="og:type" content="website"/>
      <meta property="og:type" content="https://www.mediroot.pl/"/>
      <meta property="og:title" content={ prop?.title || "Mediroot" }/>
      <meta property="og:description" content={ prop?.title || "Mediroot" }/>
      <meta property="og:locale" content="pl_PL"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://www.mediroot.pl/"/>
      <meta property="twitter:title" content={ prop?.title || "Mediroot" }/>
      <meta property="twitter:description" content={ description }/>

      <meta http-equiv="x-ua-compatible" content="ie=edge"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      <meta name="robots" content="index,follow"/>
      <meta name="language" content="pl-PL" />
      <meta name="url" content="https://www.mediroot.pl/"/>
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta name="content-security-policy" content="default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; object-src 'none'" />
      <title>
        { prop?.title || "Mediroot" }
      </title>
      <script type='application/ld+json'>
      {JSON.stringify({

"@context": "http://www.schema.org",
"@type": "LocalBusiness",
"name": "Gabinet stomatologiczny Wągrowiec | Mediroot",
"legalName":"Mediroot Urszula Korzeniowska",
"url": "https://www.mediroot.pl/",
"image":
"https://www.mediroot.pl/static/767440ed1f8853ddd6855d0d5490bcd3/8ff83/hero.webp",
"logo": "https://www.mediroot.pl/",
"priceRange" : "$",
"telephone": "613067101",
"email": "mediroot.wagrowiec@gmail.com",
"vatID": "8431611830",
"address": [
{
"type": "PostalAddress",
"addressLocality": "Wągrowiec",
"postalCode": "62-100",
"streetAddress": "Św. Wojciecha 16",
"addressRegion": "wielkopolskie",
"addressCountry": "Polska"
}
]
})}
</script>
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin></link>
    </Helmet>
  )
}
