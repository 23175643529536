import React, { ReactNode, useEffect } from 'react'
import { Link,  } from "gatsby"
import { colorGold, darkGrey, dividerColor } from '@styles/color'
import tw, { styled } from 'twin.macro';
import { StyledLink, StyledWhiteLink } from './style';
import { keyframes } from '@emotion/react';

const slideUp = keyframes`
  0% {
    transform: translateY(40px);
    opacity: 0.9;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const SubMenuContainer = styled('div')`
  background-color: ${darkGrey};
  animation: ${slideUp};
  animation-duration: 400ms;
  animation-fill-mode:forward;
`

const Container = styled('div')`
  ${tw`flex py-10 flex-col lg:flex-row`}
  max-width:1280px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px 0;
  justify-content: space-between;
  ul {
    ${tw`mb-12`}
  }
  ul:first-of-type {
    ${tw`my-12 pr-5 mr-5`}
    border-right: 1px solid ${dividerColor};
  }
  li {
    ${tw`my-10`}
  }
`

const OfertContainer = styled.div`
  ${tw`flex  justify-center lg:justify-start`}
  ul {
    ${tw`my-12`}
  }
  ul:first-of-type {
    ${tw`pr-7 mr-7`}
    border-right: 1px solid ${dividerColor};
  }
  li {
    ${tw`my-10`}
  }
`

const ContactContainer = styled.div`
  ${tw`flex flex-col justify-end items-center lg:items-end lg:justify-center`}
  ul:first-of-type {
    padding-right: 0 !important;
    margin-right: 0 !important;
    border-right: 0 !important;
    margin-top: 0 !important;
  }

  li {
    ${tw`my-3`}
  }

  span {
  ${tw`text-gray-50 text-sm mb-4 text-right`}
  }
`

const MenuLabel = styled.div`
  ${tw`tracking-widest text-right uppercase text-sm mb-4`}
  color: ${colorGold};
`

const MenuHeader = styled.div`
  ${tw`text-gray-50 text-lg mb-4 text-right`}

`

const MenuContactList = styled.ul`
  ${tw`flex justify-end`}
  border-right: 0;
  color: ${colorGold};
  li:first-of-type{
    ${tw`mx-4 px-4`}
    border-right: 1px solid ${dividerColor};

  }
  a {
    color: ${colorGold};
  }
`

export const  SubMenu = () => {
  // useEffect(() => {

  // });// end useeffect
  return (
     <SubMenuContainer>
       <Container>
        <OfertContainer>
          <ul>
            <li>
              <StyledWhiteLink to="/oferta/implantologia-wagrowiec">Implantologia</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/ortodoncja-wagrowiec">Ortodoncja</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/protetyka-wagrowiec">Protetyka stomatologiczna</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/endodoncja-wagrowiec">Endodoncja</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/stomatologia-estetyczna">Stomatologia estetyczna</StyledWhiteLink>
            </li>
          </ul>
          <ul>
            <li>
              <StyledWhiteLink to="/oferta/stomatologia-chirurgiczna">Stomatologia chirurgiczna</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/periodontologia">Choroby przyzębia</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/stomatologia-zachowawcza">Stomatologia zachowawcza</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/stomatologia-dziecieca">Stomatologia dziecieca</StyledWhiteLink>
            </li>
            <li>
              <StyledWhiteLink to="/oferta/sedacja-wziewna">Sedacja wziewna</StyledWhiteLink>
            </li>
          </ul>
        </OfertContainer>

        <div>
        </div>
        <ContactContainer>
          <MenuLabel>
            Masz pytania ?
          </MenuLabel>
          <MenuHeader>
            Skontaktuj się z nami
          </MenuHeader>
          <MenuContactList>
            <li>
              <a href="tel:61 306 71 01">61 306 71 01</a>
            </li>

            <li>
              <a href="mailto:wagrowiec.mediroot@gmail.com">
                wagrowiec.mediroot@gmail.com
              </a>
            </li>
          </MenuContactList>
          <span>
            ul. św. Wojciecha 16, Wągrowiec
          </span>
        </ContactContainer>
       </Container>

     </SubMenuContainer>
  )
}

